<h1 mat-dialog-title>{{ dialogData?.title }}</h1>
<div mat-dialog-content>
  <div *ngFor="let item of dialogData?.content">
    <p>{{ item }}</p>
    <br />
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()" cdkFocusInitial>{{ dialogData?.action }}</button>
</div>
