import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';

export interface Dialog {
  title: string;
  text: string;
  action: 'yes' | 'yesNo';
}

@Component({
    selector: 'cmp-dialog-content',
    templateUrl: './dialog-content.component.html',
    styleUrls: ['./dialog-content.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        NgFor,
        MatButtonModule,
    ],
})
export class DialogContentComponent {
  dialogData: any = {};

  constructor(private dialogRef: MatDialogRef<DialogContentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogData = data;
  }

  close(): void {
    this.dialogRef.close();
  }
}
