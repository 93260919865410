import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { map, Observable } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'cmp-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class SummaryComponent {
  /**
   * Labels
   */
  @Input() labels: any = {};
  /**
   * Data
   */
  @Input() data: any = {};

  isMobile$: Observable<boolean>;

  constructor(private responsive: BreakpointObserver) {
    this.isMobile$ = this.responsive.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(map(res => res.matches));
  }
}
