<div class="sticky-top booking-summary">
  <div class="booking-summary--wrapper">
    <div *ngIf="(isMobile$ | async) === false" class="booking-summary--title">{{ labels?.title }}</div>
    <div class="booking-summary--content">
      <div>
        <span>{{ labels?.origin }} </span><span>{{ data?.origin }}</span>
      </div>
      <div>
        <span>{{ labels?.date }} </span><span>{{ data?.date }}</span>
      </div>
      <div>
        <span>{{ labels?.people }} </span><span>{{ data?.people }}</span>
      </div>
      <div>
        <span>{{ labels?.plan }} </span><span>{{ data?.plan }}</span>
      </div>
      <div *ngIf="data?.cidery?.main">
        <span>{{ labels?.cidery }}: </span><span>{{ data?.cidery?.main }}</span>
      </div>
    </div>
    <div *ngIf="data?.total" class="booking-summary--title booking-summary--price">{{ data?.total }}€</div>
  </div>
</div>
